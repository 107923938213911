@import '~bootstrap/dist/css/bootstrap.min.css';

.main_cart{
  font-size: 45px;
  cursor: pointer;
  position: absolute;
  margin-left: 1300px;
}

.main_user {
  margin-top: 10px;
  margin-left: 1400px;
  cursor: pointer;
  position: absolute; 
  width: auto;
  display: inline-block; /* Giữ phần tử bên trong khối chính xác */
}

.main_user i {
  transition: color 0.3s; /* Thêm hiệu ứng chuyển đổi màu */
}

.main_user:hover i {
  color: green; /* Đổi màu biểu tượng khi di chuột */
}

.main_user_dn a,
.main_user_dk a {
  padding: 5px;
  text-decoration: none;
  border: 1px solid black;
  width: 120px;
  text-align: center;
  font-size: large;
  color: black;
  background-color: white;
  height: 40px;
  line-height: 30px; /* Đặt căn giữa văn bản theo chiều dọc */
  display: block; /* Đảm bảo các nút là khối hiển thị */
  transition: all 0.3s; /* Thêm hiệu ứng chuyển đổi cho tất cả thuộc tính */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Thêm bóng đổ */
  border-radius: 5px; /* Thêm bo tròn góc */
  margin-bottom: 10px; /* Thêm khoảng cách giữa các nút */
}

.main_user_dn a:hover,
.main_user_dk a:hover {
  background-color: green; /* Đổi màu nền khi di chuột */
  color: white; /* Đổi màu chữ khi di chuột */
  border-color: green; /* Đổi màu viền khi di chuột */
}

.main_user_an {
  display: none;
  position: absolute; /* Định vị chính xác */
  top: 100%; /* Đặt ngay dưới phần tử chính */
  left: -50px; /* Điều chỉnh vị trí bên trái */
  background-color: white; /* Đảm bảo nền trắng */
  border: 1px solid #ccc; /* Thêm viền để phân biệt */
  padding: 10px; /* Thêm khoảng cách bên trong */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Thêm bóng đổ lớn hơn */
  border-radius: 5px; /* Thêm bo tròn góc */
  z-index: 1000; /* Đảm bảo lớp trên cùng */

}

.user_name{
  margin-left: -45px;
  font-weight: bold;
}

.main_user:hover .main_user_an {
  display: block; /* Hiển thị khi di chuột qua */
}

.btn-xt{
  text-align: center;
  /* margin-bottom: 20px; */
}

.btn-xt button{
  width: 120px;
  height: 40px;
  border-radius: 5px;
  background-color: #00791c;
  border: 1px solid #00791c;
  transition: background-color 0.3s ease;
}

.Link-xt{
 text-decoration: none;
 color: white; 
 transition: color 0.3s ease;

}

.btn-xt button:hover{
  background-color: #00791cdf;
}

.Link-xt:hover{
  color: black;
}





.hello{
  background-color: #224229;
  height: 20px;
}

.header-index{
  height: 130px;
  background-color: #FCF9F3;
  display: flex;
  position: relative;
  align-items: center;
  justify-items: center;


}
.header-logo{
  margin-right: 150px;
  margin-left: 80px;
}
.header-link a{
  text-decoration: none;
  font-size: 20px;
  margin-left: 30px;
  color: black;
}
.header-link a:hover{
  color: #93c09d;
}


.header-menucon{
  border: 1px solid white;
  display: flex;
  background-color: white;
  padding-top: 30px;
   display: none; 
  position: absolute;
  /* margin-left: -210px;
   */
   left: 0%;
   width: 100%;
   z-index: 2;
   transition: all 0.3s; 

}

.header-menucon h5{
  font-weight: bold;
  transition: all 0.3s; 

}


div .theokieudang{
  border: 1px solid white;
  width: 334px;
  margin-left: 25px;
  margin-right: 25px;
  padding-left: 90px;
  background-color: white;
  display: block;
  position: absolute;
  transition: all 0.3s; 

}

div .theovitri{
  border: 1px solid white;
  width: 334px;
  margin-left: 359px;
  margin-right: 25px;
  padding-left: 90px;
  background-color: white;
  display: block;
  position: absolute;
  transition: all 0.3s; 


}

div .theochucnang{
  border: 1px solid white;
  width: 334px;
  margin-left: 718px;
  margin-right: 25px;
  padding-left: 90px;
  background-color: white;
  display: block;
  position: absolute;
  transition: all 0.3s; 

}

.header-menucon img{
width: 334px;
height: 300px;
margin-left: 1077px;
margin-right: 25px;
margin-top: -30px;
transition: all 0.3s; 

}


.header-menucon a{
text-decoration: none;
color: black;
}

.header-menucon a:hover{
  color: white;
}



.hiencaytrongnha:hover .header-menucon{
  display:block;
}




/* nền nền nền */

.nen img{
  height: 650px;
  width: 100%;
}

.nen-con{
  border: 1px solid rgba(255, 255, 255, 0.696);
   width: 600px;
   position: absolute;
   top: 0%;
   margin-top: 320px;
   margin-left: 200px;
   background-color: rgba(255, 255, 255, 0.696);
   height: 350px;
   padding-top: 30px;
   padding-left: 50px;
   box-shadow: 5px 5px;
   z-index: 1;
}

.nen-con p{
  width: 500px;
  text-align: justify;
  font-size: 25px;
  color: #224229;
  margin-top: 20px;
  
}

.nen-con h4{
  font-weight: bold;
  color: #224229;
  font-size: 30px;

}


.nen-lh{
  width: 120px;
  height: 40px;
  border: 3px solid #224229;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.696);
  color: #224229;
  font-weight: bold;
  margin-left: 120px;
  margin-top: 20px;
}

.nen-mn{
  width: 120px;
  height: 40px;
  border: 3px solid #224229;
  border-radius: 20px;
  background-color: #224229;
  color: white;
  font-weight: bold;
  margin-left: 20px;

}

.nen-lh:hover{
  background-color: #224229;
  color: white;
}


/* nav Danh mục sản phẩm */
.nav-dmsp{
  background-color: #fff5cb93;
  height: 670px;
  padding-top: 30px;
}

.nav-dmsp h3{
  text-align: center;
}

.nav-dmsp-main{
  margin-left: 219px;
  margin-right: 219px;
  margin-top: 30px;
}
.nav-dmsp-main-con{
  float: left;
  margin-left: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  
}
.nav-dmsp-main-con img{
  width: 250px;
  height: 250px;
  transition: transform 0.3s ease;
}

.nav-dmsp-main-con p{
  position: absolute;
  border: 1px solid #224229;
  color: white;
  margin-top: -50px;
  width: 250PX;
  text-align: center;
  background-color: #224229;
}

.nav-dmsp-main-con img:hover{
  transform: scale(1.2);
}

/* show sản phẩm */
.main-product{
  width: 100%;
  background-color: #FCF9F3;
  padding-top: 20px;
}



.pr{
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between;
   */
  padding-left: 75px;
  padding-right: 75px;

}

.main-product h3{
  text-align: center;
}

.product{
 margin-left: 10px;
 margin-right: 10px;
  border: 2px solid white;
  background-color: #FAF0E6;
  height: auto;
  text-align: center;
  margin-bottom: 20px;
}

.product-con{
  overflow: hidden;
  width: 250px;
  height: 300px;

}
.product-con img{
  width: 250px;
  height: 300px;
  position: relative;
  transition: transform 0.3s ease;
}

.product-con svg{
  position: absolute;
  margin-top: -290px;
  margin-left: 210px;
  width: 25px;
  height: 25px;
  display: none;
  color: white;
}

/* .product-con svg:hover{
  color: rgb(255, 5, 5);
  cursor: pointer;
} */
  
.product-con img:hover{
  transform: scale(1.5);
}

.product-mn{
  position: absolute;
  /* width: 250px; */
  width: 150px;
  height: 60px;
  z-index: 1;
  border: 3px solid white;
  margin-top: -180px;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 10px;
  font-size: larger;
  font-weight: bolder;
  color: white;
  display: none;
margin-left: 48px;

}
.pagin{
  text-align: center;

}
.product-tvg{
  position: absolute;
  margin-top: -57px;

  width: 250px;
 
  border: 1px solid #224229;
  background-color: #224229;
  color: white;
  display: none;


}

.product-name-price{
  padding-top: 10px;
  text-align: center;

}

.product-name{
  color: #00791c;
  font-size: 17px;
  width: 250px;
}


.product-con:hover svg{
  display: block;
}

.product-con:hover button{
  display: block;
}


.product-con button:hover{
  color: black;
}
.xct{
  color: white;
  text-decoration: none;
}
.product-mn .xct:hover{
  color: black;
}

.product-xt{
  text-align: center;
}

.product-xt button{
  width: 170px;
  height: 40px;
  border-radius: 10px;
  background-color: #224229;
  color: white;

}

.product-xt button:hover{
  color: black;
}




footer{
  background-color: #224229;
  color: white;
}

.footer-logo img{
  height: 300px;
  width: 300px;
  margin-top: -100px;
  margin-left: 40px;

}

.footer-logo p{
  width: 350px;
  text-align: justify;
  margin-left: 30px;
  margin-top: -50px;
  margin-right: 100px;

}

.footer-main{
  display: flex;
  line-height: 40px;
  padding-top: 50px;
}


.footer-vct {
  margin-left: 80px;
}

.footer-vct a{
  display: block;
  text-decoration: none;
  color: white;
}


/* detail sp */
.detail-sp{
  display: flex;
  margin-top: 50px;
  padding-left: 120px;
  }
  
  .detail-img{
      overflow: hidden;
      margin-top: 30px;
      margin-bottom: 30px;
      margin-left: 10px;
      cursor: pointer;
  }
  
  .detail-img img{
      height: 500px;
      width: 500px;
      transition: transform 0.3s ease;

  }
  
  .detail-img img:hover{
     transform: scale(1.4);
  }
  
  .detail-sp h1{
  color: #00791c;
  font-weight: bold;
  text-align: center;     
  position: absolute;   
  margin-top: -70px;
  margin-left: 40%;
}

.detail-tt{
  margin-top: 50px;
}

  .detail-tt p{
      width: 700px;
      margin-left: 90px;
      margin-top: 20px;
      font-size: 20px;
      text-align: justify;
      
  }

  .detail-mn{
      display: block;
      height: 50px;
      width: 150px;
      margin-top: 400px;
      margin-left: -700px;
      background-color: #00791c;
      border: #00791c;
      color: white;
      border-radius: 5px;
  }

  .detail-tvg {
      height: 50px;
      width: 150px;
      margin-top: 400px;
      margin-left: 30px;
      border-radius: 5px;
  }


  .detail-mn:hover{
      color: black;
  }

  .detail-tvg:hover{
      background-color: #00791c;
      color: white;
  }


  /* from dk */


  .formthanhtoan {
      max-width: 400px;
      margin: 50px auto;
      background: #fff;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  

  
  .form-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
  }

  
  .formthanhtoan input{
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
      transition: border-color 0.3s ease;
  }
  
  .formthanhtoan button {
      padding: 10px 20px;
      background-color: #ffc107;
      color: #212520;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      width: 400px;
      height: 37.6px;
      font-size: 18px;
      margin-bottom: 20px;
  }
  
  .formthanhtoan button:hover {
      color: white;

  }

  .footer-vct a:hover{
      color: #212520;
  }


  .product_moi{
    position: absolute;
    z-index: 1;
    background-color: red;
    border: 1px solid red;
    width: 50px;
    height: 30px;
    padding-top: 2px;
    color: white;
    font-weight: bolder;
}


/* CSS cho đánh giá sản phẩm */
.binh_luan {
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.binh_luan > div {
  margin-bottom: 15px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.binh_luan > div:last-child {
  border-bottom: none;
}

.binh_luan p {
  margin: 5px 0;
  color: #333;
}

.binh_luan p strong {
  font-size: 1.1em;
  color: #555;
}

.binh_luan .date {
  opacity: 0.7;
  font-size: 0.9em;
  margin-top: -17px;
}

.binh_luan .comment {
  margin-top: -12px;
  font-size: 1em;
  line-height: 1.5;
}

.form_bl {
  border: 2px solid #ccc;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  margin: 30px auto;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form_bl label {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  color: #333;
}

.form_bl input, .form_bl textarea {
  width: 100%;
  max-width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
}

.form_bl input:focus, .form_bl textarea:focus {
  border-color: #66afe9;
  outline: none;
  box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
}

.form_bl button {
  width: 100%;
  max-width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: none;
  background-color: #28a745;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form_bl button:hover {
  background-color: #218838;
}


.showcart{
  width: 1200px;
  text-align: center;
  margin: 0 auto;
}

.showcart th{
  background-color: green;
  color: white;
  padding-top: 3px;
}

.showcart td{
  background-color: white;
  border-bottom: 1px solid black;
}


.tb_tt{
  /* border: 1px solid black; */
  width: 1200px;
display: flex;
justify-content: end;
padding-right: 17px;
background-color: white;
margin: 0 auto;
padding-top: 2px;

}


.tb_xoa{
  width: 70px;
  height: 40px;
  background-color: yellow;
  border: 1px solid yellow;
  border-radius: 5px;
  font-weight: bolder;
  transition: color 0.3s ease;

}

.tb_xoa:hover{
  color: white;
  background-color: rgba(255, 255, 0, 0.666);
}

.tb_thanhtoan{
  width: 130px;
  height: 40px;
  background-color: green;
  border: 1px solid green;
  font-weight: bolder;
  margin-left: 970px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 7px;
}

.tb_thanhtoan_tt{
  text-decoration: none;
  color:  white;
  transition: color 0.3s ease;
}

.tb_thanhtoan_tt:hover{
  color: black;
background-color: rgba(0, 128, 0, 0.642);
}

.tb_xoaall{
  background-color: red;
  border: 1px solid red;
  height: 40px;
  border-radius: 5px;
  color: white;
  font-weight: bolder;
  font-size: larger;
  margin-left: 160px;
  margin-bottom: 60px;
  transition: color 0.3s ease;

}

.tb_xoaall:hover{
color: black;
background-color: rgba(255, 0, 0, 0.66);
}

#giohang h2{
  margin-left: 15px;
  padding-top: 5px;
  font-weight: bolder;
  text-align: center;
}

#giohang p{
  text-align: center;
}


#frmthanhtoan {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-top: 40px;
  margin-bottom: 40px;
}

#frmthanhtoan h2 {
  text-align: center;
  margin-bottom: 20px;
}

#frmthanhtoan div {
  margin-bottom: 15px;
}

#frmthanhtoan label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

#frmthanhtoan input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

#frmthanhtoan button {
  width: 100%;
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

#frmthanhtoan button:hover {
  background-color: #218838;
}

.thanhphantrang { list-style: none; text-align: center;
  padding-bottom: 10px;}
  .thanhphantrang li { 
    display: inline-block; margin: 5px; width: 30px; height: 30px; padding-top: 3px; 
    background: white; }
  .thanhphantrang li a { color:black !important; font-weight: bold;}
  .thanhphantrang li.selected {background: green }
  .thanhphantrang li:hover {background: green; cursor: pointer; }
    
  .search{
    padding-top: 20px;
    text-align: center;
  }

  .search input{
    width: 700px;
    height: 45px;
    border-radius: 13px;
    padding-left: 10px;
    margin-bottom: 20px;
    margin: 0 auto;
    margin-bottom: 20px;
    
  }

  .search label{
    font-size: larger;
    font-weight: bold;
    margin-right: 20px;
  }
  .icon_search{
    height: 30px;
    position: absolute;
    margin-left: -40px;
    margin-top: 8px;
    opacity: 50%;
  }


  .form {
    width: 550px;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-left: 570px;
    padding-left: 70px;   
    margin-top: -10px; 
    margin-top: 50px;
  }


  .form h1{
      margin-top: -5px;
      text-align: center;
      margin-left: -30px;
      
  }

.form label{
  display: block;
  font-weight: bold;
  font-size: larger;
  margin-bottom: 5px;
}  

.form .input{
  display: block;
  margin-bottom: 15px;
  height: 40px;
  width: 400px;
  border-radius: 5px;
  border: 0.5px solid black;

}

.form button{
  display: block;
  height: 50px;
  width: 400px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #224229;
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
  transition: background-color 0.3s ease ;
  transition: color 0.3s ease ;
}

.form button:hover{
  color: black;
  background-color: #07bb31;
  border: 1px solid #07bb31;
}

.cart_sosp{
  position: absolute;
  font-size: 15px;
  border-radius: 50%;
  width: 20px;
  height: 22px;
  background-color: green;
  color: white;
  text-align: center;
  margin-top: -20px;
  margin-left: 35px;

}

.icon_cart{
  color: #00791c;
  transition: color 0.3s ease;
}

.icon_cart:hover{
  color: #00791c79;

}



.hearder_admin h3{
  margin-top: 15px;
  margin-left: 10px;
}
.admin_user{
  position: absolute;
  margin-left: 1150px;
  margin-top: 25px;
}
.hearder_admin{
    height: 50px;
    display: flex;
    border-bottom: 3px solid black;
    margin-left: -8px;
    padding-left: 5px;
    align-items: center;
    margin-top: -8px;
    }
    
    .hearder_admin h3{
      margin-top: 15px;
      margin-left: 10px;
    }
    
    
    .hearder_admin span{
    color: #00791c;
    }
    
    .hearder_admin .bi-list{
    padding-top: 5px;
    margin-left: 22px;
    }
    
    
    
    /* NAV NAV */
    .nav_admin a{
        display: block;
        text-decoration: none;
        color: white;
    }
    
    
    
    .nav_admin{
    float: left;
    width: 250px;
    margin-left: -8px;
    background-color: #224229;
    height: 690px;
    display: block;
    }
    
    
    
    .nav_admin_link{
    display: flex;
    align-items: center;
    padding-left: 10px;
    height: 50px;
    cursor: pointer;
    }
    
    .nav_admin_link i{
    margin-right: 20px;
       font-size: 30px;
       color: white;
    align-items: center;
    
    }
    
    
    .nav_admin_link:hover{
        background-color: gray;
    }
    
    .nav_admin_link:hover a{
        color: black;
    }
    
    
    .link_dashboard{
        display: flex;
        padding-left: 5px;
    }
    
    .link_dashboard p{
        margin-left: 5px;
        margin-top: 13px;
    }
    
    .link_dashboard a{
        text-decoration: none;
        color: rgba(0, 0, 0, 0.462);
    }
    
    .link_dashboard a:hover{
        color: black;
        
    }
    
    
    .tbsp{
        /* padding-top: 30px; */
        /* padding-left: 15%; */
        width: 100%;
    }
    

    .admin_showsp a{
      text-decoration: none;
      color: black;
   
    }

  
    
    .admin_showsp{
        border: 2px solid rgba(0, 0, 0, 0.214);  
        text-align: center;
        width: 60%;
        position: absolute;
        margin-left: 320px;
        border-collapse: collapse;
    
    }
    
    .admin_showsp td{
    
        border-bottom: 2px solid rgba(0, 0, 0, 0.214);
        border-top: 2px solid rgba(0, 0, 0, 0.214);
    }
    .admin_showsp th{
        /* border: 3px solid black; */
        background-color: black;
        color: white;
    }
    
    .admin_showsp button{
        width: 70px;
        border-radius: 5px;
        height: 40px;
        cursor: pointer;
        background-color: white;
    }
    
    .admin_showsp button:hover{
      background-color: #00791c;
    }
    .admin_showsp button:hover a{
      color: white;
    }

    .admin_showsp input{
      width: 30px;
    }

    
    .btn-them{
        height: 40px;
        width: 150px;
        border-radius: 5px;
        margin-bottom: 10px;
        background-color: #224229;
        color: white;
        cursor: pointer;
        margin-top: -43px;  
        float: right;
        margin-right: 218px;
    }

    .btn-them a{
      color: white;
      text-decoration: none;
  } 
    
    .btn-them a:hover{
      color: black;
    }
    
    
    
    
/*       
      form {
          width: 550px;
          margin: 20px;
          padding: 20px;
          border: 1px solid #ccc;
          border-radius: 5px;
          background-color: #f9f9f9;
          margin-left: 470px;
            padding-left: 65px;    
        }
      
      
        form h1{
            margin-top: -5px;
            text-align: center;
            margin-left: -30px;
        }
      
      form label{
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
      }  
      
      form input{
        display: block;
        margin-bottom: 5px;
        height: 35px;
        width: 500px;
        border-radius: 5px;
      }
      
      form button{
        display: block;
        margin-bottom: 5px;
        height: 40px;
        width: 508px;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 20px;
        background-color: #224229;
        color: white;
      }
      
      form button:hover{
        color: black;
      } */
      
     
      
      .admin-qlsp-header-qlsp{
      background-color: #224229;
      color: white;
      height: 40px;
      }
      
      .admin-qlsp-header-qlsp h3{
        margin-left: 10px;
      }


      .sp_quantity{
        width: 30px;
      }



      .btn-add-product {
        display: inline-block;
        margin-bottom: 20px;
        padding: 10px 20px;
        background-color: #4CAF50;
        color: white;
        text-decoration: none;
        border-radius: 5px;
        margin-left: 10px;        
      }
      
      .btn-add-product:hover {
        background-color: #45a049;
      }
      
      
      .table-products {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
      }
      
      .table-products th, .table-products td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: center;
      }
      
      .table-products th {
        background-color: #f2f2f2;
      }
      
      .btn-edit, .btn-delete {
        padding: 5px 10px;
        margin: 2px;
        border: none;
        border-radius: 3px;
        cursor: pointer;
      }
      
      .btn-edit {
        background-color: #4CAF50;
        color: white;
      }
      
      .btn-edit:hover {
        background-color: #45a049;
      }
      
      .btn-delete {
        background-color: #f44336;
        color: white;
      }
      
      .btn-delete:hover {
        background-color: #e53935;
      }
      
      
    
      .an-hien-select {
        padding: 8px;
        border-radius: 4px;
        border: 1px solid #ccc;
        font-size: 16px;
        background-color: #f9f9f9;
        color: #333;
      
      }
      
      .an-hien-select:focus {
        border-color: #007bff;
        outline: none;
      }
      
      .an-hien-option {
        padding: 8px;
        font-size: 16px;
        color: #333;
      
      }


      .form-container {
        max-width: 400px;
        margin: 0 auto;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
        background-color: #f9f9f9;
        margin-top: 20px;
      }
      
      .form-title {
        text-align: center;
        /* margin-bottom: 20px; */
      }
      
      .form-error {
        color: red;
        text-align: center;
        /* margin-bottom: 10px; */
      }
      
      /* .form-group {
        margin-bottom: 15px;
      } */
      
      .form-label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
      }
      
      .form-input {
        width: 358px;
        /* padding: 10px; */
        border: 1px solid #ccc;
        border-radius: 3px;
        box-sizing: border-box;
      }
      
      .form-button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 3px;
        background-color: green;
        color: white;
        font-size: 16px;
        cursor: pointer;
      }
      
      .form-button:hover {
        background-color: rgba(0, 128, 0, 0.841);
      }
      

.AnHien{
  display: flex;
}

.AnHien input{
  height: 10px;
  width: 10px;
}

